<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router/auto";
import { setPageTitle } from "@/utils/pageTitle";
import SpecialOrdersLayout from "@/layouts/SpecialOrdersLayout.vue";
import useSpecialOrders from "@/hooks/useSpecialOrders";

const route = useRoute();
const documents = ref([]);

const { fetchSpecialOrder, specialOrder, fetchSpecialOrderDocuments, specialOrderDocumentsUploading, uploadSpecialOrderDocuments } = useSpecialOrders();

onMounted(async () => {
  await fetchSpecialOrder(route.params.id);
  documents.value = await fetchSpecialOrderDocuments(route.params.id);
  setPageTitle('SLO');
});

const getIconType = (file) => {
  if (file.mime_type.includes('pdf')) return 'file-pdf'
  if (file.mime_type.includes('text')) return 'file-pdf'
  if (file.mime_type.includes('csv')) return 'file-csv'
  return 'image'
}

const upload = async (e) => {
  const file = e.target.files[0];
  const formData = new FormData();
  formData.append("file", file);
  const response = await uploadSpecialOrderDocuments(route.params.id, formData);
  if (response) {
    documents.value = await fetchSpecialOrderDocuments(route.params.id);
  }
}
</script>

<template>
  <SpecialOrdersLayout v-if="specialOrder" :special-order="specialOrder">
    <div class="d-flex flex-wrap pa-2">
      <v-card
        v-for="doc in documents" :key="doc.id"
        class="ma-2"
        max-width="250"
        max-height="300"
        variant="outlined"
        link
        target="_blank"
        :href="doc.url"
      >
        <v-img v-if="getIconType(doc) === 'image'"
          height="100px"
          :src="doc?.url"
        >
          <template v-slot:placeholder>
            <div class="d-flex align-center justify-center fill-height">
              <v-progress-circular
                color="grey-lighten-4"
                indeterminate
              ></v-progress-circular>
            </div>
          </template>
        </v-img>
        <div v-else class="text-center mt-4">
          <v-icon size="45" icon="mdi-file" />
        </div>
        <v-card-title class="text-wrap">{{ doc.filename }}</v-card-title>
        <v-card-subtitle class="text-wrap">{{ new Date(doc.created_at).toLocaleString('en-US') }}</v-card-subtitle>

        <v-card-text>
          <div>Added by {{ doc.created_by.name }}</div>
        </v-card-text>
      </v-card>

      <v-card
        variant="outlined"
        class="ma-2 d-flex align-center justify-center"
        max-width="250"
        min-height="75"
        @click="$refs.file.click()"
      >
        <v-icon size="45" v-if="!specialOrderDocumentsUploading" icon="mdi-plus" />

          <v-progress-circular
            v-if="specialOrderDocumentsUploading"
            :width="8"
            :size="50"
            color="primary"
            class="mb-16"
            indeterminate
          ></v-progress-circular>

      </v-card>
      <input v-show="false" type="file" ref="file" @change="upload" />
    </div>
  </SpecialOrdersLayout>
</template>